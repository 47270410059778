import { subDays } from 'date-fns'
import { CulturalEventFragment } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import AddTicket from './AddTicket'
import TicketItemIteratee from './TicketsList'
import TotalSum from './TotalSum'

const Tickets: FC<CulturalEventFragment> = (culturalEvent) => {
  const upcoming = new Date() < subDays(culturalEvent.startsAt, 1)
  const sortedTickets = culturalEvent.tickets.toSorted().reverse()

	return $(Fragment, null,
		upcoming && $(AddTicket, culturalEvent),
		sortedTickets.map(TicketItemIteratee),
    $(TotalSum, culturalEvent))
}

export default Tickets
