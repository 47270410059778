import { Card, Flex, Label, Text, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { CulturalEventStatusEnum, CulturalEventsQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const CulturalEventListItemIteratee = (props: CulturalEventListItemType) =>
  $(CulturalEventListItem, { key: props.id, ...props })

const CulturalEventListItem: FC<CulturalEventListItemType> = ({
  id,
  title,
  startsAt,
  endsAt,
  status
}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const date = !startsAt
    ? intl.formatMessage({ id: 'culturalEvent.noDate' })
    : $(Flex, { direction: 'column' }, 
      status === 'DRAFT' && $(FormattedDate, { value: startsAt, day: '2-digit', month: 'long' }),
      $(Flex, null,
        $(FormattedDate, {
          value: startsAt,
          timeStyle: 'short'
        }),
        ' – ',
        $(FormattedDate, {
          value: endsAt,
          timeStyle: 'short'
        }))
      )

  const children = $(Flex, { direction: 'column', justifyContent: 'space-between', height: '100%' },
    $('div', { className: spacing({ mb: 2 })}, 
      $(Text, { variant: 'body-2' }, title || intl.formatMessage({ id: 'culturalEvent.noName' })),
      $(Text, { variant: 'body-1', color: 'hint' }, date)),
    $(StatusChip, { status }))

  return $(Card, {
    className: spacing({ p: 4 }),
    style: { wordWrap: 'break-word' },
    type: 'action',
    onClick: () => navigate(`/events/${id}`),
    children
    })
}

const StatusChip: FC<{ status: string }> = ({ status }) => {
  const formattedStatus = Object.keys(CulturalEventStatusEnum).find(key => key.toLowerCase() === status.toLowerCase())
  const theme = status === 'CANCELED' ? 'danger' : 'unknown'

  if (status === 'ARCHIVED' || status === 'CANCELED') 
    return $('div', { style: { width: 'fit-content' }}, 
      $(Label, { theme }, 
        $(FormattedMessage, { id: `culturalEvent.status.${formattedStatus}` as MessageIds })))
}

type CulturalEventListItemType = CulturalEventsQuery['culturalEvents'][number]

export default CulturalEventListItemIteratee