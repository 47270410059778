import { Flex } from "@gravity-ui/uikit"
import Dniwe from "components/Dniwe"
import Empty from "components/Empty"
import { eventsLoadingElements } from "components/LoadingElements/LoadingElements"
import { createElement as $, FC, Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { CulturalEventsProps } from ".."
import CulturalEventListItemIteratee from "./CulturalEventListItem"
import GroupEventsItem from "./GroupEventsItem"
import fetchMoreCulturalEvents from "./fetchMoreCulturalEvents"
import { CulturalEvent, groupCulturalEvents } from "./groupCulturalEvents"

const Content: FC<CulturalEventsProps> = (props) => {
  const data = props.data
  if (!data) {
    if (props.loading)
      return eventsLoadingElements
    return $(Empty)
  }
  
  const groupedEvents = groupCulturalEvents(data.culturalEvents)
  const shouldShowStatus = !!data.draftCulturalEvents.length
  const before = data.culturalEvents[data?.culturalEvents.length - 1].startsAt
  const onReached = () => fetchMoreCulturalEvents(before, props.fetchMore)

  return $(Fragment, null,
    $(DraftCulturalEvents, { events: data.draftCulturalEvents }),
    $(GroupEventsItem, { events: groupedEvents, shouldShowStatus }),
    // FIXME this does not work for some reason
    data.culturalEvents.length < data.aggregateCulturalEvents.aggregate!.count
    // FIXME use skeleton
    && $(Dniwe, { onReached }))
}

const DraftCulturalEvents = ({ events }: { events: CulturalEvent[] }) => {
  if (!events.length) return null

  return $(Flex, { direction: 'column' }, 
    $('div', { className: 'event-status-container'},
      $(FormattedMessage, { id: `culturalEvent.status.Draft` })),
    $('div', { 
      className: 'events-container', 
      style: { gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, 1fr))'} 
      }, 
      events.map(CulturalEventListItemIteratee)))
}

export default Content