import { dateTime } from '@gravity-ui/date-utils/build/dateTime'
import PlusIcon from '@gravity-ui/icons/Plus'
import { Button, Card, Flex, Icon, spacing } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import useFab from 'hooks/useFab'
import { CulturalEventsQuery, useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, Fragment, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CulturalEventsProps } from '..'
import Attendance from '../CulturalEvent/Attendance'
import CulturalEvent from '../CulturalEvent/Desktop'
import Content from './Content'

const Desktop: FC<CulturalEventsProps> = (props) => {

  useFab($(Fab))

  return $(Fragment, null,
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(CulturalEventContainer)
      }),
      $(Route, { 
        path: '',
        element: $(CulturalEvents, props)
      })))
}

const CulturalEventContainer = () =>
  $(Flex, { gap: 2, direction: 'column' },
    $(Card, {
      view: 'filled',
      style: { maxWidth: 500 },
      children: $(CulturalEvent),
      className: spacing({ p: 4 })
      }),
    $(Attendance))

const CulturalEvents: FC<CulturalEventsProps> = (props) =>
  $(Flex, {
    className: spacing({ mr: 2 }),
    direction: 'column',
    gap: 2
    },
    $(Content, props))

const Fab: FC = () => {
  const navigate = useNavigate()
  const { ziferblatId } = useContext(ZiferblatContext)

  const [mutate, { loading }] = useUpsertCulturalEventMutation({
    variables: { 
      input: {
        ziferblatId: ziferblatId,
        startsAt: dateTime().add(1, 'day').toDate()
      }
    },
    updateQueries: {
      CulturalEvents: (prev, next) => {
        const nextRaw = next.mutationResult.data?.upsertCulturalEvent
        if (nextRaw) {
          const nextEvent: CulturalEventsQuery['draftCulturalEvents'][number] = {
            __typename: 'CulturalEvent',
            id: nextRaw.id,
            title: nextRaw.title,
            startsAt: nextRaw.startsAt,
            endsAt: nextRaw.endsAt,
            status: nextRaw.status
          }
          return {
            ...prev,
            draftCulturalEvents: [nextEvent, ...prev.draftCulturalEvents]
          }
        }
        return prev
      }
    }
  })

  return $(Button, {
    size: 'xl',
    view: 'action',
    loading,
    onClick: () => mutate()
      .then(({ data }) =>
        data?.upsertCulturalEvent &&
          navigate(`/events/${data.upsertCulturalEvent.id}`)),
    },
    $(Icon, { data: PlusIcon }))
}

export default Desktop