import { Checkin,CheckinFragment } from "queries"
import { getName } from "../Mobile/Content"

export enum Validation {
  Valid,
  Empty,
  Exists
}

export type Value = { name: string, validation: Validation }

export const validate = (
  checkins: CheckinFragment[],
  value: Pick<Checkin, 'id' | 'name' | 'user'>
): Validation => {
  if (value.name?.length === 0) return Validation.Empty
  for (const checkin of checkins)
    if (prepareName(getName(value)) === prepareName(getName(checkin)))
      if (checkin.id !== value.id)
        return Validation.Exists
  return Validation.Valid
}

const prepareName = (name: string) => name.trim().toLowerCase()