import Empty from 'components/Empty'
import loadingElements from 'components/LoadingElements/LoadingElements'
import { SearchContext } from 'components/MobileMenu/SearchContext'
import { CheckinsQuery } from 'queries'
import { createElement as $, FC, Fragment, useContext } from 'react'
import { useIntl } from 'react-intl'
import { CheckinsProps } from '..'
import CheckinListItemIteratee from '../CheckinListItem'
import { CheckinsContainer, GroupIteratee } from '../Desktop'
import groupCheckinsByCulturalEvents from '../groupedCheckins'

const Content: FC<CheckinsProps> = ({
  data,
  loading
}) => {
  if (loading && !data) return loadingElements
  if (!data) return 'no data'
  if (data.checkins.length === 0) return $(Empty)
  return $(CheckinsList, data)
}

const CheckinsList: FC<CheckinsQuery> = ({ checkins }) => {
  const intl = useIntl()
  const searchRegex = useContext(SearchContext)
  const search = searchRegex?.source || ''
  const groups = groupCheckinsByCulturalEvents(intl, search, checkins)

  return $(Fragment, null, 
    groups.length === 1 && groups[0].name === intl.formatMessage({ id: 'checkins.eventless' })
      ? $(CheckinsContainer, null,
          groups[0].checkins.map(CheckinListItemIteratee))
      : groups.map(GroupIteratee))
}

export const getName = (checkin: Pick<CheckinsQuery['checkins'][0], 'user' | 'name'>) =>
  checkin.user?.id || checkin.name || '-'

export default Content