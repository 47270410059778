import { Card, Flex, Text, spacing } from '@gravity-ui/uikit'
import { AttendanceQuery, CompensationCurrencyEnum as CompensationCurrency, CompensationUnitEnum as CompensationUnit, CulturalEventFragment, useAttendanceQuery } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

const Attendance: FC<AttendanceProps> = ({ id, compensation }) => {
  const { data } = useAttendanceQuery({ variables: { id } })
  if (!data) return null
  const sum = data.attendees.reduce((result, item) => result += item.sum!, 0)
  const count = data.attendees.length
  if (!count) return null

  return $(Fragment, null,
    !sum
      ? $('div', { className: spacing({ p: 2 }) })
      : $(Card, {
          view: 'filled',
          className: spacing({ p: 4 }),
          children: $(Stats, { count, sum, compensation })
        }),
    data.attendees?.map(AttendanceIteratee))
}

const Stats: FC<StatsProps> = ({
  sum,
  count,
  compensation
}) => {
  const intl = useIntl()
  return $(Fragment, null,
    $(Flex, { justifyContent: 'space-between' }, 
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.count' })), count),
    $(Flex, { justifyContent: 'space-between' }, 
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.sum' })),
        $(FormattedNumber, { value: sum, style: 'currency', currency: 'RUB' })),
    compensation &&
      $(Compensation, { sum, count, compensation }))
}

const Compensation: FC<CompensationProps> = ({
  sum,
  count,
  compensation
}) => {
  const intl = useIntl()
  let compensationSum = sum * (1 / (100 / compensation.amount))
  if (compensation?.unit === CompensationUnit.Fixed)
    compensationSum = count * compensation.amount
  return $(Flex, { justifyContent: 'space-between' },
    $(Text, null,
      intl.formatMessage({ id: 'culturalEvent.compensation' })),
    compensation.currency === CompensationCurrency.Money
      ? $(FormattedNumber, { style: 'currency', currency: 'RUB', value: compensationSum })
      : $(FormattedMessage, { id: 'minutes', values: { minutes: compensationSum }}))
}

const AttendanceIteratee = (props: AttendanceItem) =>
  $(AttendanceListItem, { key: props.id, ...props })

const AttendanceListItem: FC<AttendanceItem> = ({
  sum,
  visit,
  checkin
}) =>
  $(Card, {
    view: 'filled',
    className: spacing({ px: 4, py: 2 }),
    children: $(Flex, { justifyContent: 'space-between' },
      $(Text, null, visit?.name || checkin?.name),
      $(Text, null,
        $(FormattedNumber, { value: sum!, style: 'currency', currency: 'RUB' })))
      })

type AttendanceProps = Pick<CulturalEventFragment, 'id' | 'compensation'>
type AttendanceItem = AttendanceQuery['attendees'][number]
type StatsProps = {
  sum: number
  count: number
  compensation: CulturalEventFragment['compensation']
}
type CompensationProps = StatsProps & { compensation: NonNullable<StatsProps['compensation']> }

export default Attendance
