import NavigationRail from 'components/NavigationDesktop/NavigationRail'
import { createElement as $, FC, useState } from 'react'
import { DeviceProps } from '..'
import FabContext, { FabElement } from './FabContext'
import './style.scss'

const Desktop: FC<DeviceProps> = ({
  routes,
  navItems,
  data
}) => {
  const [fab, setFab] = useState<FabElement>()
  // const [compact, setCompact] = useState(true)
  
  return $('div', { className: 'main-desktop' },
    $(FabContext.Provider, { value: setFab },
      $(NavigationRail, { navItems, fab, data }),
      $('div', { className: 'main-desktop_content' }, routes)))
}

export default Desktop