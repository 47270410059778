import { DelayedTextInput } from '@gravity-ui/components'
import { LogoTelegram } from '@gravity-ui/icons'
import { Flex, Link, Loader, Text, spacing } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { SearchAliasQuery, StaffQuery, useSearchAliasQuery } from 'queries'
import { createElement as $, FC, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AddStaffRoleSelect } from './NewRoleSelect'
import { getAlias } from './StaffListItem'

const AddStaff: FC<StaffQuery> = () => {
  const intl = useIntl()
  const [value, setValue] = useState('')
  const { ziferblatId } = useContext(ZiferblatContext)
  const { data, previousData, loading } = useSearchAliasQuery({ 
    variables: { 
      term: `%${value}%`, 
      ziferblatId 
    }, 
    fetchPolicy: 'cache-and-network' 
  })
  const aliases = data || previousData
  const count = aliases?.authAlias.length
  const hint = $(Text, { className: 'settings_add-staff_hint'}, 
    $(FormattedMessage, { id: 'settings.staff.hint' }))
    
  return $(Flex, { direction: 'column', gap: 2 },
    count 
      ? $(Flex, { direction: 'column', gap: 2, className: spacing({ pl: 1 }) }, 
      aliases.authAlias.map(UserTermIterate))
      : hint,
    $(DelayedTextInput, { 
      autoFocus: true,
      size: 'l',
      value,
      placeholder: intl.formatMessage({ id: 'alias.search'}).replace(':', ''),
      onUpdate: setValue,
      rightContent: $('div', { style: { width: 40 }},
        loading && $(Loader, { size: 's' }))
      }))
}

const UserTermIterate = (props: SearchResult) =>
  $(UserItem, {key: props.id, ...props })

const UserItem: FC<SearchResult> = (alias) => {
  const aliases = alias.user?.alias
  const name = getAlias('name', aliases) ?? ''
  const surname = getAlias('surname', aliases) ?? ''
  const telegramUsername = getAlias('telegram_username', aliases)

  return $(Flex, { 
    key: alias.user?.id,
    as: 'div', 
    direction: 'column', 
    justifyContent: 'space-between', 
    gap: 2,
  },
    $(Flex, { gap: 2, justifyContent: 'space-between', alignItems: 'center' }, 
      $(Flex, { direction: 'column', gap: 1 }, 
        $(Text, { variant: 'subheader-1', className: 'settings_staff-item_name' }, 
          `${name} ${surname}`),
        $(Link, { 
          href: `https://t.me/${telegramUsername}`, 
          target: '__blank', 
          view: 'secondary', 
          style: { 
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            width: 'max-content',
            color: !telegramUsername ? 'var(--g-color-text-warning)' : ''
          }}, 
          $(LogoTelegram),
          telegramUsername ?? $(FormattedMessage, { id: 'settings.staff.noTelegram' }))),
        $(AddStaffRoleSelect, { userId: alias.user?.id })))
}

type SearchResult = SearchAliasQuery['authAlias'][0]

export default AddStaff