import { Plus } from '@gravity-ui/icons'
import MobileList from 'components/MobileList'
import CallToAction from 'components/MobileMenu/CallToAction'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { createElement as $, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Content, { TariffsProps } from './Content'
import AddTariff from '../AddTariff'


const Mobile: FC<TariffsProps> = (props) =>
  $(MobileMenuContainer, { // TODO search for tariffs 
    fab: $(AddButton),
    label: 'header.button.search.tariffs'
    },
    $(MobileList, null, 
      $(Content, props),
      $(AddTariff, props)))

const AddButton = () => {
  const navigate = useNavigate()
  return $(CallToAction, {
    data: Plus,
    onClick: () => navigate(`/tariff/add`)
  })
}

export default Mobile