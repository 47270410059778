import { IntlShape, useIntl } from 'react-intl'

const useDuration = (unit: Unit, value: number) => {
  const intl = useIntl()
  const preparedValue = value * units[unit]
  const result: string[] = []
  const dateParts = intl.formatDateToParts(preparedValue, { timeZone: 'UTC' })
  const timeParts = intl.formatTimeToParts(preparedValue, { timeZone: 'UTC' })
  for (const part of [...dateParts, ...timeParts])
    result.push(...processPart(intl, part))
  return result.join(' ')
}

const processPart = (intl: IntlShape, { value, type: unit }: Intl.DateTimeFormatPart) => {
  const parsedValue = parseInt(value, 10)
  const result = []
  if (parsedValue > 0) {
    if (['day', 'hour', 'minute'].includes(unit)) {
      if (!(unit === 'day' && parsedValue === 1))
        result.push(intl.formatNumber(parsedValue, { unit, unitDisplay: 'long', style: 'unit' }))
    }
  }
  return result
}

const units = {
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60
}

export type Unit = keyof typeof units 

export default useDuration