import { CulturalEventStatusEnum } from 'queries'

export const groupCulturalEvents = (events: CulturalEvent[]): GroupedEvents => {
	const grouped: GroupedEvents = {}

	events.forEach((event: CulturalEvent) => {
		const date = new Date(event.startsAt)
		const year = date.getFullYear().toString()
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const dateKey = date.toISOString().split('T')[0]

		if (!grouped[year]) {
			grouped[year] = {}
		}
		if (!grouped[year][month]) {
			grouped[year][month] = {}
		}
		if (!grouped[year][month][dateKey]) {
			grouped[year][month][dateKey] = []
		}
		grouped[year][month][dateKey].push(event)
	})

	return grouped
}

export type CulturalEvent = {
	__typename?: 'CulturalEvent' | undefined
	id: string
	title: string
	startsAt: Date
	endsAt: Date
	status: CulturalEventStatusEnum
}

export type GroupedEvents = {
	[year: string]: {
		[month: string]: {
			[date: string]: CulturalEvent[]
		}
	}
}
