import { Flex } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'

const Unauthorized: FC = () =>
  $(Flex, {
    style: { 
      padding: '2rem',
      textAlign: 'center'
    },
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'column',
    gap: 4
    },
    $('div', { style: { fontSize: 'x-large' }}, '🤔'),
    $(FormattedMessage, { id: 'unauthorized.notabene' }))

export default Unauthorized