import { Plus } from "@gravity-ui/icons"
import { Button, DropdownMenu, DropdownMenuItem, Icon } from "@gravity-ui/uikit"
import ZiferblatContext from "components/ZiferblatContext"
import { UserFragment, UserFragmentDoc, useAddRoleMutation, useRolesQuery } from "queries"
import { createElement as $, FC, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { RoleType, Roles } from "./StaffListItem"

const NewRoleSelect: FC<SelectProps> = ({ loading, roleItems }) => {
  return $(DropdownMenu, {
    renderSwitcher: (props) => 
      $(Button, { ...props, loading }, 
        $(Icon, { data: Plus })),
      items: roleItems
  })
}

export const AddStaffRoleSelect: FC<{ userId: any }> = ({ userId }) => {
  const intl = useIntl()
  const { data } = useRolesQuery({ fetchPolicy: 'cache-first' })
  const [mutate] = useAddRoleMutation()
  const getOptionLabel = (name: string) => intl.formatMessage({ id: `role.${name}` as Roles })
  const { ziferblatId } = useContext(ZiferblatContext)
  const navigate = useNavigate()
  
  const roleItems = data?.accessRole?.map((role: RoleType['role']) => {
    return {
      className: 'settings_select-item',
      text: getOptionLabel(role.name),
      action: () => {
        mutate({
          update: (cache, { data }) => {
            const id = data?.addRole?.subject.id
            cache.modify({
              fields: {
                users: (prev, { readField }) => {
                  const nextUser = cache.writeFragment({
                    data: data?.addRole?.subject,
                    fragment: UserFragmentDoc
                  })
                  if (!prev.find((user: UserFragment) => readField('id', user) === id)) {
                    return [nextUser, ...prev]
                  }
                  return prev
                }
              }
            })
          },
          variables: { 
            roleId: role.id,
            userId,
            ziferblatId 
          }
        }
      ).then(() => navigate('/staff'))
    }
  }}) 

  return $(DropdownMenu, {
    renderSwitcher: (props) => 
      $(Button, { ...props }, 
        $(Icon, { data: Plus })),
      items: roleItems
  })
}

  export type SelectProps = {
    loading?: boolean
    roleItems: (DropdownMenuItem | DropdownMenuItem[])[] | []
  }

  export default NewRoleSelect