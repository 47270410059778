import { createElement as $, FC, useContext } from 'react'
import { QrReader } from 'react-qr-reader'
import { TicketCheckinMutation, useBankTransactionLazyQuery, useTicketCheckinMutation } from 'queries'
import { MutationResult } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import ZiferblatContext from 'components/ZiferblatContext'

const QRCodeScanner = () => {
  const [mutate, data] = useTicketCheckinMutation()
  const [call] = useBankTransactionLazyQuery()
   const { ziferblatId } = useContext(ZiferblatContext)
  if (data.called)
    return $(Check, data)
  return $(QrReader, {
    constraints: {
      facingMode: 'environment' 
    },
    onResult: async (result) => {
      if (result) {
        const [originalTransactionId, email, sum] = result.getText().split(';')
        const { data } = await call({ variables: { originalTransactionId }})
        const transactionId = data?.paymentTransaction[0].id
        mutate({
          variables: {
            object: {
              transactionId,
              visit: {
                data: {
                  name: email,
                  sum: parseInt(sum),
                  checkedInAt: new Date(),
                  checkedOutAt: new Date(),
                  ziferblatId
                }
              }
            }
          }
        })
      }
    }
  })
}

const Check: FC<MutationResult<TicketCheckinMutation>> = ({
  error,
  data
}) => {
  if (error) {
    console.log(error)
    return $('div', null,
      $(FormattedMessage, { id: 'checkins.qr.error' }))
  } if (data?.insertCulturalEventPrepaidAttendanceOne?.id) {
    return $(Success, data.insertCulturalEventPrepaidAttendanceOne)
  }
  return $('div', null, '...')
}

const Success: FC<NonNullable<TicketCheckinMutation['insertCulturalEventPrepaidAttendanceOne']>> = ({
  transaction,
  visit
}) => {
  const { title, startsAt } = transaction!.ticket!.culturalEvent
  const name = visit?.name
  return $('div', null,
    // FIXME styling & formatting
    $(FormattedMessage, {
      id: 'checkins.qr.success',
      values: { name, title, startsAt: new Date(startsAt)}
    }))
}

export default QRCodeScanner