import { MobileProvider, ThemeProvider } from '@gravity-ui/uikit'
import { createElement as $, FC, PropsWithChildren } from 'react'
import './theme.scss'

const ThemedApp: FC<PropsWithChildren> = ({ children }) =>
	$(ThemeProvider, null, 
    $(MobileProvider, null, 
      children))

// const ThemedApp: FC<PropsWithChildren> = ({ children }) => {
//   const light = useTheme(chroma(78, 216, 233), false)
//   const dark = useTheme(chroma(78, 216, 233), true)

// const styles = {
//   'body': {
//     margin: 0
//   },
//   '*': {
//     boxSizing: 'border-box'
//   },
//   '.yc-root': {
//     '--g-sheet-content-paddings': 0,
//     '&_theme_dark': {
//       ...dark,
//     },
//     '&_theme_light': {
//       ...light
//     }
//   }
// }
// return $(ThemeProvider, {
//   // theme: prefersDarkMode ? 'dark' : 'light'
//   },
//   // $(Global, { styles }),
//   $(LayoutProvider, null,
//     $(MobileProvider, { mobile: false },
//       children)))
// }

export default ThemedApp
