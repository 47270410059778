import FabContext from 'pages/Main/Desktop/FabContext'
import { ReactElement, useContext, useEffect } from 'react'

const useFab = (fab: ReactElement) => {
  const setFab = useContext(FabContext)
  useEffect(() => {
    setFab(fab)
    return () => setFab(undefined)
  }, [fab, setFab])
}

export default useFab