import MobileList from 'components/MobileList'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { createElement as $, FC } from 'react'
import { CulturalEventsProps } from '..'
import AddCulturalEvent from '../CulturalEvent/Mobile/AddCulturalEvent'
import Content from './Content'

const CulturalEvents: FC<CulturalEventsProps> = (props) =>
  $(MobileMenuContainer, {
    fab: $(AddCulturalEvent),
    label: 'header.button.search.events'
    },
    $(MobileList, null, 
      $(Content, props)))

export default CulturalEvents