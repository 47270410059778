async function telegramAuth(
  options: Options,
  ref?: React.RefObject<HTMLElement>
): Promise<TelegramAuthResult['result']> {
  const popupUrl = getPopupUrl(options)

  window.open(
    popupUrl,
    'telegram_oauth_bot' + options.botId,
    getPopupParams(ref)
  )

  return new Promise((resolve, reject) => {
    // FIXME add rejection
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== origin) return
      const data: TelegramAuthResult = JSON.parse(event.data)
      resolve(data.result)
      window.removeEventListener('message', messageHandler)
    }
    window?.addEventListener('message', messageHandler)
    window?.addEventListener('error', console.log) // replace with reject Promise
  })
}

const getPopupUrl = (options: Options) => {
  const url = new URL(origin + '/auth')
  const params = new URLSearchParams({
    bot_id: String(options.botId),
    origin:
      window.location.origin ||
      window.location.protocol + '//' + window.location.hostname,
    return_to: window.location.href,
  })
  if (options.requestAccess) {
    params.set('request_access', String(options.requestAccess))
  }
  if (options.lang) {
    params.set('lang', options.lang)
  }
  url.search = params.toString()
  return url
}

function getPopupParams(ref?: React.RefObject<HTMLElement>) {
  const size = { width: 480, height: 480 }
  const position = { left: 480, top: 480 }

  if (ref?.current) {
    const rect = ref.current.getBoundingClientRect()
    position.left = rect.left - size.width / 2
    position.top = size.height / 2
  }

  const params = [
    `width=${size.width}`,
    `height=${size.height}`,
    `left=${position.left}`,
    `top=${position.top}`,
    `status=0`,
    `location=0`,
    `menubar=0`,
    `toolbar=0`,
  ].join(',')

  return params
}

const origin = 'https://oauth.telegram.org'
// || 'https://oauth.tg.dev'

export const convertToJs = (
  data: TelegramAuthResult['result']
): TelegramResult => ({
  id: data.id,
  authDate: data.auth_date,
  hash: data.hash,
  username: data.username,
  ...(data.first_name && { firstName: data.first_name }),
  ...(data.last_name && { lastName: data.last_name }),
  ...(data.photo_url && { photoUrl: data.photo_url }),
})

type Options = {
  botId: number
  requestAccess: boolean
  lang: string
}

type TelegramAuthResult = {
  event: 'auth_result'
  origin: string
  result: {
    auth_date: number
    first_name?: string
    hash: string
    id: number
    last_name?: string
    photo_url?: string
    username: string
  }
}

type TelegramResult = {
  authDate: number
  firstName?: string
  hash: string
  id: number
  lastName?: string
  photoUrl?: string
  username: string
}

export default telegramAuth
