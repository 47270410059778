import { Button, Icon } from '@gravity-ui/uikit'
import { SVGIconData } from '@gravity-ui/uikit/build/esm/components/Icon/types'
import { createElement as $, FC, PropsWithChildren } from 'react'
import './style.scss'

const AdornmentButton: FC<PropsWithChildren<{ onClick: () => void, data: SVGIconData }>> = ({
  onClick, data
}) =>
  $(Button, {
    size: 'l',
    onClick: (event) => { event.stopPropagation(); onClick() },
    view: 'flat',
    style: { zIndex: 1000 }
    },
    $(Icon, { data }))

export default AdornmentButton