import useDuration, { Unit } from 'hooks/useDuration'
import { FC } from 'react'

const FormattedDuration: FC<FormattedDurationProps> = ({ value, unit }) => {
  const result = useDuration(unit, value)
  return result
}

type FormattedDurationProps = {
  value: number
  unit: Unit
}

export default FormattedDuration