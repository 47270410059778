import { Button, ButtonProps, Icon, IconProps } from '@gravity-ui/uikit'
import { createElement as $, FC  } from 'react'

const CallToAction: FC<ButtonProps & Pick<IconProps, 'data'>> = ({
  data,
  ...props
}) =>
  $(Button, {
    size: 'l',
    view: 'action',
    pin: 'clear-round',
    ...props
    },
    $(Icon, { data }))

export default CallToAction