import { PauseFill, Xmark } from '@gravity-ui/icons'
import { Button, Checkbox, Flex, Icon, Loader, Text, TextInput, TextProps, spacing } from '@gravity-ui/uikit'
import { CheckinsQuery, useAddAttendanceMutation, useChangeVisitNameMutation, useCheckOutMutation, useCheckinQuery, useRemoveAttendanceMutation } from 'queries'
import { createElement as $, Dispatch, FC, PropsWithChildren, ReactElement, SetStateAction, useRef, useState } from 'react'
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import NameInput from './NameInput'
import { Pause, Resume } from './PauseResume'
import './style.scss'
import { validate } from './validation'

const ViewCheckin: FC<CheckinsQuery> = (props) => {
  const params = useParams<'id'>()
  const id = params.id!
  const { data, loading } = useCheckinQuery({ 
    variables: { id }, 
    fetchPolicy: 'cache-and-network'
  })

  if (loading)
    return $(Flex, { justifyContent: 'center'}, 
      $(Loader, { size: 's' }))
  const checkin = data?.checkin
  if (!checkin?.id)
    return $(Navigate, { to: '/checkins' })
  return $(CheckinContent, { checkin, ...props, })
}

const CheckinContent: FC<CheckinContentProps> = ({
  checkin,
  checkins,
  upcomingCulturalEvents
}) => {
  const validation = validate(checkins, { id: checkin.id, name: checkin.name })
  const [sum, setSum] = useState(checkin.sum || 0)
  const [mutate, { loading }] = useCheckOutMutation({
    variables: {
      id: checkin.id,
      sum: sum
    },
    update: (cache, { data }) =>
      cache.modify<{ checkins: CheckinsQuery['checkins'] }>({
        fields: {
          checkins(existingCheckins, { readField }) {
            const checkOut = data?.checkOut
            if (checkOut)
              return existingCheckins.filter(checkinItem => readField('id', checkinItem) !== checkOut.id)
            else return existingCheckins;
          },
        },
      })
  })
  const navigate = useNavigate()
  const [changeName] = useChangeVisitNameMutation()
  const intl = useIntl()
  const controlRef = useRef<HTMLInputElement>(null)
  const handleCheckout = () => {
    try {
      mutate()
      .then(({ data, errors }) => { 
        if (data) navigate('/checkins')
        else console.log(errors) 
      })
    }
    catch (error) { console.log(error) }
  }

  const pause = checkin.pause[0]
  const pauses = checkin.pause.toReversed()

  return $(Flex, {
    direction: 'column',
    gap: 4
    },
    $(NameInput, {
      setName: (name) => changeName({
        variables: { id: checkin.id, name },
        optimisticResponse: {
          updateCheckin: {
            ...checkin,
            name
          }
        }
      }),
      name: checkin.name || '',
      validation,
      }),
    $(TextInput, {
      className: 'sum-input',
      controlRef,
      size: 'xl',
      value: isNaN(sum) ? '' : sum.toString(),
      leftContent : $('div', { 
        style: { paddingLeft: '12px', paddingRight: '.25rem' }
        }, '₽'),
      rightContent: $(Flex, { 
        alignItems: 'center',
        gap: 2,
        className: spacing({ mr: 2 })
        }, 
        $(Button, { 
          view: 'flat',
          onClick() { 
            setSum(0)
            controlRef?.current?.focus()
          },
          style: { alignItems: 'center' }
          }, $(Xmark))),
      onUpdate: (value) => setSum(parseInt(value))
    }),
    $(CulturalEvents, { checkin, upcomingCulturalEvents }),
    $(DiscountCheckbox, { sum, setSum }),
    $(Flex, { direction: 'column', gap: 1 },
      pauses.map(PauseIteratee)),
    pause?.endedAt === null
      ? $(Resume, pause)
      : $(Flex, null,
          $(Button, {
            loading,
            view: 'action',
            size: 'xl',
            width: 'max',
            pin: 'round-brick',
            disabled: isNaN(parseInt(sum.toString())),
            onClick: handleCheckout,
            },
            intl.formatMessage({ id: 'checkins.checkout' })),
          $(Pause, { checkinId: checkin.id })))
}

const CulturalEvents: FC<Pick<CheckinContentProps, 'checkin' | 'upcomingCulturalEvents'>> = ({
  checkin,
  upcomingCulturalEvents
}) => {
  const result: ReactElement[] = []
  const [add, addMeta] = useAddAttendanceMutation()
  const [remove, removeMeta] = useRemoveAttendanceMutation()
  const culturalEvents = [...upcomingCulturalEvents, ...checkin.attendance.map(({ culturalEvent }) => culturalEvent)]
    .filter(checkIfUnique)
  for (const culturalEvent of culturalEvents) {
    const variables =  {
      checkinId: checkin.id,
      culturalEventId: culturalEvent.id
    }
    const loading = addMeta.loading || removeMeta.loading
    const checked = !!checkin.attendance.find((attendance) => culturalEvent.id === attendance.culturalEvent.id)
    const mutate = checked ? remove : add
    result.push(
      $('div', { key: culturalEvent.id },
        $(Checkbox, {
          size: 'l',
          onChange: () => mutate({ variables }),
          content: culturalEvent.title,
          disabled: loading,
          checked,
        })))
  }
  return result
}

const checkIfUnique = <T extends { id: string }>(value: T, index: number,array: T[])=>
  array.findIndex(next=> next.id === value.id) === index

const PauseItem: FC<Checkin['pause'][0]> = (pause) => {
  const startedAt = new Date(pause.startedAt)
  const iconProps = { data: PauseFill, size: 17 }

  if (!pause.endedAt) {
    return $(PauseContainer, null,
      $(Icon, { ...iconProps }),
      $(FormattedMessage, { 
        id: 'checkin.pause', 
        values: { startedAt }
      }))
  }

  const endedAt = new Date(pause.endedAt)

  return $(PauseContainer, { props: { color: 'hint' }},
    $(Icon, { ...iconProps }),
    $(Flex, { justifyContent: 'space-between', width: '88px' },
      $(FormattedTime, { value: startedAt }),
      $(Text, null, '–'),
      $(FormattedTime, { value: endedAt }))
  )
}

const PauseContainer: FC<PropsWithChildren & { props: TextProps }> = ({ children, props }) => 
  $(Text, { ...props }, 
    $(Flex, { 
      alignItems: 'center', 
      gap: 2
      }, children))

const PauseIteratee = (props: Checkin['pause'][0]) => 
  $('div', {key: props?.id}, 
    $(PauseItem, { key: props?.id, ...props }))

const DiscountCheckbox: FC<{ 
  sum: number, 
  setSum: Dispatch<SetStateAction<number>>
  }> = ({ sum, setSum }) => {
  const [originalSum] = useState(sum)
  const intl = useIntl()

  return $(Checkbox, {
    size: 'l',
    onUpdate: (checked) => setSum(checked ? getDiscount(sum, 5) : originalSum),
    content: intl.formatMessage({ id: 'checkin.discount.five' })
  })
}

const getDiscount = (sum: number, percentage: number) => 
  Math.floor(sum - ((percentage / 100) * sum))

export type Checkin = CheckinsQuery['checkins'][number]

// const Sum: FC<Checkin> = ({ sum }) =>
//   $(Text, { variant: 'body-2'},
//     $(FormattedNumber, { value: sum!, style: 'currency', currency: 'rub' }))

type CheckinContentProps = CheckinsQuery & { checkin: Checkin }

export default ViewCheckin