import { useApolloClient } from '@apollo/client'
import { TrashBin, Xmark } from '@gravity-ui/icons'
import { Button, Flex, Icon, Modal, spacing } from '@gravity-ui/uikit'
import { useRemoveTicketMutation } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import './style.scss'


const RemoveTicketButtonWithConfirmation: FC<{ id: string }> = ({ id }) => {
  const client = useApolloClient()
  const [mutate, { loading }] = useRemoveTicketMutation({ variables: { id }})
  const [open, setOpen] = useState(false)

  const handleTicketRemove = () => {
    mutate()
      .then(() => {
        client.refetchQueries({ // FIXME update cache instead
          include: ["CulturalEvent"],
        })
      })
  }

  return $(Fragment, null, 
    $(Button, {
      view: 'flat-secondary',
      size: 'xs',
      onClick: () => setOpen(true)
      }, 
      $(Icon, { data: TrashBin })),
    $(Modal, { 
      open, 
      onClose: () => setOpen(false),
      },
      $(Flex, { 
        direction: 'column', 
        gap: 4, 
        alignItems: 'center', 
        className: spacing({ p: 4 }) 
        },
        $(FormattedMessage, { id: 'culturalEvent.tickets.confirmRemove' }), 
        $(Flex, { gap: 2 },
          $(Button, { 
            view: 'action',
            onClick: () => handleTicketRemove(),
            loading
            },
            $(Icon, { data: TrashBin }),
            $(FormattedMessage, { id: 'culturalEvent.remove' })), 
          $(Button, { 
            view: 'outlined',
            onClick: () => setOpen(false),
            disabled: loading
            }, 
            $(Icon, { data: Xmark }),
            $(FormattedMessage, { id: 'culturalEvent.cancel' })))
    )))
}


export default RemoveTicketButtonWithConfirmation