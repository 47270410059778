import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { DelayedTextInput } from '@gravity-ui/components/build/cjs/components/DelayedTextInput'
import { Validation } from './validation'

const NameInput: FC<NameInputProps> = ({
  name,
  setName,
  validation,
}) => {
  const intl = useIntl()
  const error = {
    error: true,
    supportingText: intl.formatMessage({ id: 'errors.nameTaken' })
  }
  return $(DelayedTextInput, {
    size: 'xl',
    onUpdate: (value) => setName(value),
    autoFocus: true,
    placeholder: intl.formatMessage({ id: 'checkins.add.name' }),
    value: name,
    supportingText: '',
    delay: 500,
    ...validation === Validation.Exists && error
  })
}

type NameInputProps =  {
  setName: (value: string) => void
  name: string
  validation: Validation
}

export default NameInput