// import { MeQuery } from "queries";
import { createContext } from "react"

const ZiferblatContext = createContext<ZiferblatContextType>({
  name: '',
  ziferblatId: ''
})

export type ZiferblatContextType = {
  name: string
  ziferblatId: string
}

export default ZiferblatContext