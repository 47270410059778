import { SelectOption, Select } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import { useFormikContext } from "formik"
import { CulturalEventStatusEnum } from "queries"
import { Fragment, createElement as $ } from "react"
import { useIntl } from "react-intl"
import { Form } from "./CulturalEventForm"

const StatusSelect = ({ isMobile }: { isMobile: boolean }) => {
  const intl = useIntl()
  const { values, handleChange } = useFormikContext<Form>()
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const options: SelectOption[] = Object.entries(CulturalEventStatusEnum).map(([key, value]) => ({
    content: intl.formatMessage({ id: `culturalEvent.status.${key}` as MessageIds }),
    value: value as CulturalEventStatusEnum,
  }))  

  const renderSelectedOption = (option: SelectOption) => $(Fragment, null, option.content || '')

  return $(Select, { 
    width: isMobile ? 'max' : undefined,
    value: [values.status], 
    onUpdate: (value) => onChange('status')(value[0]), 
    options,
    renderSelectedOption
  })
}

export default StatusSelect