import { LogoTelegram } from "@gravity-ui/icons"
import { Card, Flex, Link, Text, spacing } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import ZiferblatContext from "components/ZiferblatContext"
import { AuthAlias, RolesQueryResult, StaffQuery, useAddRoleMutation, useRolesQuery } from "queries"
import { createElement as $, FC, useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import NewRoleSelect from "./NewRoleSelect"
import GrantItemIteratee from "./RoleItem"
import './style.scss'

const StaffListItem: FC<StaffType> = (props) => {
  const intl = useIntl()
  const name = getAlias('name', props.alias) ?? ''
  const surname = getAlias('surname', props.alias) ?? ''
  const telegramUsername = getAlias('telegram_username', props.alias)
  const userId = props.id
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useAddRoleMutation()
  const roles: RolesQueryResult = useRolesQuery({ fetchPolicy: 'cache-first' })

  const rolesOptions = roles?.data?.accessRole?.filter((role: RoleType['role']) => 
    !props.grants.some(grant => grant.role.name === role.name))

  const sortingFunction = (a: RoleType['role'], b: RoleType['role']) =>
    intl.formatMessage({ id: `role.${a.name}` as Roles})
    .localeCompare(intl.formatMessage({ id: `role.${b.name}` as Roles}))

  const sortedOptions = rolesOptions?.toSorted(sortingFunction) || [] 
  const noRolesLeft = props.grants.length === roles.data?.accessRole.length
  const getOptionLabel = (name: string) => intl.formatMessage({ id: `role.${name}` as Roles })

  const roleItems = sortedOptions.map((role: RoleType['role']) => {
    return {
      className: 'settings_select-item',
      text: getOptionLabel(role.name),
      action: () => { 
        mutate({ 
          variables: { 
            roleId: role.id,
            userId,
            ziferblatId 
      }})}
  }}) 
  
  const children = 
    $(Flex, { 
      as: 'div', 
      height: '100%',
      direction: 'column', 
      justifyContent: 'space-between', 
      gap: 2
    },
      $(Flex, { direction: 'column', gap: 1 }, 
        $(Text, { variant: 'subheader-1', className: 'settings_staff-item_name' }, 
          `${name} ${surname}`),
        $(Link, { 
          href: `https://t.me/${telegramUsername}`, 
          target: '__blank', 
          view: 'secondary', 
          style: { 
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            width: 'max-content',
            color: !telegramUsername ? 'var(--g-color-text-warning)' : ''
          }}, 
          $(LogoTelegram),
          telegramUsername ?? $(FormattedMessage, { id: 'settings.staff.noTelegram' })),
      $(Flex, { as: 'div', gap: 1, wrap: 'wrap', justifyContent: 'start' },
        props.grants.map(GrantItemIteratee),
        !noRolesLeft
          && $(NewRoleSelect, { 
            loading, 
            roleItems
          }))))

  return $(Card, {
    key: props.id,
    className: `${spacing({ p: 4 })} settings_staff-item`,
    view: 'filled',
    type: 'container',
    children
  })
}

const StaffListItemIteratee = (props: StaffType) =>
  $(StaffListItem, { key: props.id, ...props })

export const getAlias = (type: string, aliases?: Pick<AuthAlias, 'type' | 'value'>[]) =>
  aliases?.find(alias => alias.type === type)?.value || null
  
export type StaffType = StaffQuery['users'][number]

export type RoleType = Pick<StaffType['grants'][number], 'id' | 'role'>

export type Roles = Extract<MessageIds, `role.${string}`>

export default StaffListItemIteratee