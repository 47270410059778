import { useLayoutContext } from '@gravity-ui/uikit'
import { useCulturalEventsQuery } from 'queries'
import { createElement as $, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import CulturalEvent from './CulturalEvent'
import DesktopList from './CulturalEventsList/Desktop'
import MobileList from './CulturalEventsList/Mobile'
import ZiferblatContext from 'components/ZiferblatContext'
import { addYears } from 'date-fns/fp/addYears'

const CulturalEvents = () =>
	$(Routes, null,
		$(Route, {
			path: ':id',
			element: $(CulturalEvent)
		}),
		$(Route, { 
			path: '/*',
			element: $(CulturalEventsList)
		}))

const before = addYears(10, new Date())

const CulturalEventsList = () => {
	const { activeMediaQuery } = useLayoutContext()
	const { ziferblatId } = useContext(ZiferblatContext)
	const component = activeMediaQuery === 's' ? MobileList : DesktopList
	const props = useCulturalEventsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			ziferblatId,
			before
		}
	})

	return $(component, props)
}

export type CulturalEventsProps = ReturnType<typeof useCulturalEventsQuery>

export default CulturalEvents
