import { useApolloClient } from '@apollo/client'
import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { dateTime, dateTimeParse } from '@gravity-ui/date-utils'
import { Xmark } from '@gravity-ui/icons'
import { Button, Card, Flex, TextInput, spacing, useLayoutContext } from '@gravity-ui/uikit'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { CulturalEventTicket, useAddTicketMutation } from 'queries'
import { createElement as $, FC, RefObject, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const TicketsForm: FC<TicketFormProps> = ({ setAdding, culturalEventId }) => {
  const [mutate] = useAddTicketMutation()
  const client = useApolloClient()
	const formik = useFormik<FormikProps>({
		initialValues: {
			activeFrom: new Date(),
			price: 0,
      maxCount: undefined,
      customUrl: ''
		},
    validate: ({ customUrl }) => 
      customUrl && !isValidUrl(customUrl) 
        ? { customUrl: 'invalid URL' }
        : undefined,
		onSubmit: (values) => { 
      mutate({ variables: {
        culturalEventId,
        activeFrom: values.activeFrom,
        price: values.price,
        maxCount: values.maxCount,
        customUrl: values.customUrl
      }})
      client.refetchQueries({ // FIXME update cache instead
        include: ["CulturalEvent"],
      })
      setAdding(false)
    },
    onReset: () => setAdding(false)
		// validateOnBlur: true,
		// enableReinitialize: true,
	})
	return $(FormikProvider, { value: formik }, $(Form))
}

const Form: FC = () => {
  const { 
    values, 
    handleBlur, 
    handleChange, 
    handleSubmit, 
    handleReset,
    errors
  } = useFormikContext<Partial<CulturalEventTicket>>()
  const activeFrom = dateTimeParse(values.activeFrom)
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const direction = isMobile ? 'column' : 'row'
  const onBlur = (name: string) => () => handleBlur({ target: { name }})
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const activeFromInputRef = useRef<HTMLInputElement>(null)
  const intl = useIntl()

  const handleInputFocus = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current && isMobile) 
      try { ref.current.showPicker() }
      catch (error) { console.log(error) }
  }

  const children = $(Flex, { direction: 'column' }, 
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.price' }) }, 
      $(TextInput, {
        autoFocus: true,
        value: getValue(values.price),
        rightContent: $('div', { className: spacing({ px: 2 }) }, $(FormattedMessage, { id: 'ruble' })),
        onUpdate: (value) => onChange('price')(parseInt(value)),
        onBlur: onBlur('price'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.stats.sum' })
      })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.maxCount' }) }, 
      $(TextInput, {
        value: getValue(values.maxCount!),
        rightContent: $('div', { className: spacing({ px: 2 }) }, 
          $(FormattedMessage, { id: 'culturalEvent.tickets.count', values: { count: '' }})),
        onUpdate: (value) => onChange('maxCount')(parseInt(value)),
        onBlur: onBlur('maxCount'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.maxCount.empty' })
      })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.planned' }) },
      $(Flex, null,
        $('div', { style: { position: 'relative' }},
          $(DatePicker, {
            format: 'HH:mm',
            pin: 'round-clear',
            value: activeFrom,
            onFocus: () => handleInputFocus(activeFromInputRef),
            onUpdate(value) {
              onChange('activeFrom')(value)
            },
            }),
          $('input', { 
            ref: activeFromInputRef, 
            type: 'time', 
            value: activeFrom?.format('HH:mm'),
            onChange(event) {
              const newActiveFrom = dateTimeParse(values.activeFrom)?.format(`YYYY-MM-DDT${event.target.value}Z`)
              onChange('activeFrom')(dateTime({ input: newActiveFrom }))
            },
            style: { 
              position: 'absolute',
              pointerEvents: 'none',
              left: 0,
              bottom: 0,
              opacity: 0, 
            }})),
          $(DatePicker, {
            value: activeFrom,
            pin: 'clear-round',
            onUpdate: (date) => {
              onChange('activeFrom')(date)
            },
            format: 'DD.MM.YYYY',
            onBlur: onBlur('activeFrom'),
          }))),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.customUrl' }) }, 
      $(TextInput, {
        type: 'url',
        validationState: !!errors.customUrl ? 'invalid' : undefined,
        onUpdate: (value) => onChange('customUrl')(value),
        onBlur: onBlur('customUrl'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.customUrl.placeholder' })
      })),
    $(FormControls, { 
      price: values.price, 
      handleSubmit, 
      handleReset
    }))

  return $(Card, { 
    className: spacing({ p: 4 }), 
    view: 'filled', 
    children
  })
}

const getValue = (value: number | undefined) => {
  if (!value || isNaN(value)) return ''
  return value.toString()
}

const FormControls: FC<ControlsProps> = ({ price, handleSubmit, handleReset }) => {
  const { dirty, isValid } = useFormikContext<Partial<CulturalEventTicket>>()

  return $(Flex, { direction: 'column', gap: 2 },
    $(Button, { 
      view: 'action', 
      disabled: !price || !dirty || !isValid, 
      onClick: () => handleSubmit()
      }, 
      $(FormattedMessage, { id: 'culturalEvent.form.save' })),
    $(Button, { 
      view: 'outlined',
      onClick: handleReset
      }, 
      $(Flex, { gap: 1, alignItems: 'center' }, 
        $(Xmark), 
        $(FormattedMessage, { id: 'culturalEvent.form.close' }))))
}

const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch (error) {
    return false
  }
}

type FormikProps = Pick<CulturalEventTicket, 'activeFrom' | 'price' | 'maxCount' | 'customUrl' >

type ControlsProps = {
  price: number | undefined
  handleSubmit: () => void
  handleReset: () => void
}

type TicketFormProps = {
  setAdding: (arg: boolean) => void
  culturalEventId: string
}

export default TicketsForm