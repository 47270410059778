import { Button, Flex } from '@gravity-ui/uikit'
import { useTelegramLoginMutation } from 'queries'
import { createElement as $, FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as LogoSvg } from '../logoFull.svg'
import telegramAuth, { convertToJs } from './telegramWidget'

const Login: FC<{ name: string, id: number }> = ({ id }) => {
  const [authLoading, setAuthLoading] = useState(false)
  const [mutate, mutationOptions] = useTelegramLoginMutation()
  const intl = useIntl()
  const loading = mutationOptions.loading || authLoading

  const onClick = async () => {
    setAuthLoading(true)
    const result = await telegramAuth({
      botId: id,
      requestAccess: true,
      lang: intl.locale,
    })
    const input = convertToJs(result)

    await mutate({ variables: { input } })
      .then(() => window.location.reload())

    // setAuthLoading(false)
  }

  return $(Flex, {
    // justifyContent: 'center',
    alignItems: 'center',
    direction: 'column',
    gap: 4,
    style: { paddingTop: '30vh' }
    },
    $(LogoSvg, { style: { height: '20vh' }}),
    $(Button, {
      onClick,
      view: 'action',
      size: 'l',
      loading: loading
      },
      intl.formatMessage({ id: `login${loading ? '.loading' : ''}`})))
}

export default Login
