import { Plus } from "@gravity-ui/icons"
import { Button, Flex, Icon, Modal, spacing } from "@gravity-ui/uikit"
import useFab from "hooks/useFab"
import { createElement as $, FC } from "react"
import { Route, Routes, useMatch, useNavigate } from "react-router-dom"
import AddStaff from "../AddStaff"
import Content, { StaffProps } from "../Content"

const Desktop: FC<StaffProps> = (props) => {
  const match = useMatch('/staff/:page')
  const isFormOpen = !!match?.params.page
  const navigate = useNavigate()
  useFab($(Add))

  return $(Flex, {
    gap: 2,
    direction: 'column',
    },
    $(Modal, { open: isFormOpen, onClose: () => navigate('/staff') },
      $('div', { className: spacing({ p: 4 })},
        $(Routes, null,
          $(Route, {
            path: 'add',
            element: $(AddStaff, props.data)
            })))),
    $(Content, props))
}

const Add = () => {
  const navigate = useNavigate()
  return $(Button, {
    size: 'xl',
    view: 'action',
    onClick: () => navigate(`/staff/add`, { replace: true })
    },
    $(Icon, { data: Plus }))
}

export default Desktop