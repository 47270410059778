import { Flex, spacing } from '@gravity-ui/uikit'
import { FC, PropsWithChildren, createElement as $ } from 'react'

const MobileList: FC<PropsWithChildren> = ({
  children
}) =>
  $(Flex, {
    gap: 2,
    direction: 'column',
    className: spacing({ p: 2 })
    }, children)

export default MobileList