import { Flex, Text, spacing } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import { createElement as $, FC, Fragment } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"
import CulturalEventListItemIteratee from "./CulturalEventListItem"
import { CulturalEvent, GroupedEvents } from "./groupCulturalEvents"
import './style.scss'

const GroupEventsItem: FC<GroupedEventsProps> = ({ events, shouldShowStatus }) =>
  $(Fragment, null,
    shouldShowStatus && $('div', { className: 'event-status-container'},
      $(FormattedMessage, { id: `culturalEvent.status.Published` as MessageIds })),
    Object.entries(events).sort().reverse().map(([year, month]) => 
      $(EventsThisYear, { key: year, year, month }))
  )

const EventsThisYear: FC<YearContainerProps> = ({ year, month }) => {
  const isNotCurrentYear = (new Date()).getFullYear() !== parseInt(year, 10)
  return $('div', null,
    $('div', { className: 'event-year-container' }, isNotCurrentYear && year), 
    Object.entries(month).sort().reverse().map(([month, dates]) => 
      $(EventsThisMonth, { key: month, dates, isNotCurrentYear }))
  )
}
 
const EventsThisMonth: FC<MonthContainerProps> = ({ dates, isNotCurrentYear }) => {
  const value = new Date(Object.keys(dates)[0])
  return $('div', null, 
    $('div', { className: 'events-month-container', style: { top: isNotCurrentYear && '2rem' }},
      $('div', { className: 'events-month-container_background' }),
        $(Text, { style: { fontSize: '16px' }},
          $(FormattedDate, { value, month: 'long' }))),
    Object.entries(dates).map(([date, events]) => 
      $(EventsOnDate, { key: date, date, events, isNotCurrentYear })))
}

const EventsOnDate: FC<EventsProps> = ({ date, events, isNotCurrentYear }) => {
  return $(Flex, { className: spacing({ mb: 4 }) },
    $('div', null, 
      $('div', { 
        className: 'events-date-container', 
        style: { top: isNotCurrentYear && '56px' }
        },
        $(Text, { style: { fontSize: '24px', fontWeight: 'bold', width: '100%' }}, 
          $(FormattedDate, {
            value: date,
            day: 'numeric'
          })),
          ' ',
        $(FormattedDate, {
          value: date,
          weekday: 'long'
        }))),
    $('div', { className: 'events-container' }, 
      events.map(CulturalEventListItemIteratee)))
}

type GroupedEventsProps = { 
  events: GroupedEvents
  shouldShowStatus: boolean
}

type EventsProps = {
  date: string
  events: CulturalEvent[]
  isNotCurrentYear: boolean
}

type YearContainerProps = {
  year: string, 
  month: Record<string, Record<string, CulturalEvent[]>>, 
}

type MonthContainerProps = {
  dates: Record<string, CulturalEvent[]>, 
  isNotCurrentYear: boolean 
}

export default GroupEventsItem