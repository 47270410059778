import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { DateTime, dateTime } from '@gravity-ui/date-utils'
import PlusIcon from '@gravity-ui/icons/Plus'
import CloseIcon from '@gravity-ui/icons/Xmark'
import { Button, Flex, Icon, Text, TextInput, spacing, useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikProps, FormikProvider, useFormik } from 'formik'
import { TariffFragmentDoc, TariffsQuery, useAddTariffMutation } from 'queries'
import { createElement as $, FC, useCallback, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TariffsProps } from './Mobile/Content'

const TariffForm: FC<{ onSuccess: () => void, props: TariffsProps }> = ({
  onSuccess,
  props
}) => {

  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate] = useAddTariffMutation()
  const addTariff = useCallback((values: TariffProps) => mutate({
    variables: {
      ...values,
      activeFrom: values.activeFrom.toDate(),
      ziferblatId
    },
    update(cache, next) {
      const tariff = next.data?.addTariff
      if (tariff) {
        const currentTariff = props.data?.tariffs[0]
        if (currentTariff)
          cache.writeFragment({
            id: cache.identify(currentTariff),
            fragment: TariffFragmentDoc,
            data: {
              ...currentTariff,
              activeTo: tariff.activeFrom
            }
          })
        cache.modify<TariffsQuery>({
          fields: {
            tariffs: (prev) => {
              return [tariff, ...prev]
            }
          }
        })
      }
    }
  }), [mutate, props.data?.tariffs, ziferblatId])

  const activeTariff = props.data?.tariffs[0]
  const minDateTimeValue = dateTime(activeTariff && { input: activeTariff?.activeFrom}).add(1, 'hour').startOf('hour')
  
  const form = useFormik<TariffProps>({
    initialValues: {
      hourPrice: [],
      stopCheck: 600,
      activeFrom: minDateTimeValue
    },
    validate(props) {
      const errors: Record<string, string> = {}
      if (!props.hourPrice?.length)
        errors.hourPrice = 'Hour price is required'
      else if (props.hourPrice.some((value) => !value))
        errors.hourPrice = 'Should not have empty values'
      if (!props.stopCheck)
        errors.stopCheck = 'Stop check should be specified'
      if (minDateTimeValue > props.activeFrom)
        errors.activeFrom = 'Date must be at least one hour in the future from the current tariff'
      return errors
    },
    validateOnMount: true,
    onSubmit: (values) => addTariff(values).then(onSuccess)
    // .then(() => window.location.reload())
  })

  return $('div', { className: spacing({ p: 4 }) },
    $(TariffFormPure, {value: form, minDateTimeValue }))
}

const TariffFormPure: FC<{
  value: FormikProps<TariffProps>, 
  minDateTimeValue: TariffProps['activeFrom']
}> = ({value, minDateTimeValue}) => {
  const intl = useIntl()
  const { activeMediaQuery } = useLayoutContext()
  const direction = activeMediaQuery === 's' ? 'column' : undefined

  return $(FormikProvider, { value },
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'settings.tariff.hourPrice' })
      },
      $(FieldArray, {
        name: 'hourPrice',
        render: (props) => $(HourPrice, props)
        })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'settings.tariff.stopCheck' }) },
      $(Field, {
        name: 'stopCheck',
        component: CurrencyField
        })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'settings.tariff.activeFrom' }) },
      $(DatePicker, {
        defaultValue: minDateTimeValue,
        value: dateTime({ input: value.values.activeFrom }),
        minValue: minDateTimeValue,
        format: 'DD.MM.YYYY HH:mm',
        onUpdate: (activeFrom) => activeFrom && value?.setFieldValue('activeFrom', activeFrom)
        })),
    $(CreateTariff, value))
} 

const CreateTariff: FC<FormikProps<TariffProps>> = ({
  isValid,
  submitForm,
  isSubmitting
}) =>
  $(Button, { 
    loading: isSubmitting,
    view: 'outlined-action',
    size: 'l',
    width: 'max',
    disabled: !isValid,
    onClick: submitForm
    },
    $(FormattedMessage, { id: 'settings.tariff.create' }))

const HourPrice: FC<FieldArrayRenderProps> = (props) => {
  const intl = useIntl()
  const placeholder = intl.formatMessage({ id: 'currencyPerMinute' })
  const renderHour = (amount: number, index: number) =>
    $(TextInput, {
      key: index,
      value: amount.toString(),
      placeholder,
      onUpdate: (value) => props.replace(index, parseInt(value) || ''),
      rightContent: $(Button, {
        size: 's',
        view: 'flat',
        onClick: () => props.remove(index)
        },
        $(Icon, { data: CloseIcon }))
    })
  return $(Flex, {
    direction: 'column',
    gap: 2
    },
    props.form.values.hourPrice?.map(renderHour),
    $(Button, {
      onClick: () => props.push(''),
      },
      $(Icon, { data: PlusIcon })))
}

const CurrencyField: FC<FieldProps<number, TariffProps>> = ({
  field,
  form
}) =>
  $(TextInput, {
    name: field.name,
    value: field.value.toString(),
    onBlur: field.onBlur,
    onUpdate: (value) => form.setFieldValue(field.name, parseInt(value) || ''),
    rightContent: currency
  })

const currency =  $(Text, { className: spacing({ px: 2 }) }, $(FormattedMessage, { id: 'ruble' }))

type TariffProps = {
  hourPrice: number[]
  stopCheck: number
  activeFrom: DateTime
}

export default TariffForm
