import { Flex, Skeleton } from '@gravity-ui/uikit'
import { createElement as $ } from 'react'
import './style.scss'

const SkeletonCard = (value: number, index: number) =>
  $(Skeleton, {
    key: index,
    style: {
      borderRadius: 'var(--g-border-radius-m)',
      height: 'calc((var(--g-spacing-4) * 2) + var(--g-text-body-2-line-height) + var(--g-text-caption-2-line-height))'
      }})

const skeletonsArray = new Array(10).fill(0).map(SkeletonCard)

const loadingElements = 
  $('div', { className: 'loading-elements' }, 
    skeletonsArray)

export const gridLoadingElements =
  $('div', { className: 'grid-loading-elements' }, 
    skeletonsArray)

export const eventsLoadingElements =
  $(Flex, { gap: 4 },
    $(Flex, { direction: 'column', grow: 1, gap: 2 }, skeletonsArray),
    $(Flex, { direction: 'column', grow: 3, gap: 2 }, skeletonsArray))



export default loadingElements