import { useApolloClient } from "@apollo/client"
import { Plus } from "@gravity-ui/icons"
import { Button, Spin } from "@gravity-ui/uikit"
import { CulturalEventFragment, useAddCulturalEventImageMutation, useImageUploadUrlMutation } from "queries"
import { createElement as $, ChangeEvent, FC, useRef } from "react"

const UploadButton: FC<CulturalEventFragment> = (props) => {
	const ref = useRef<HTMLInputElement>(null)
  const [getUploadUrl] = useImageUploadUrlMutation()
  const [mutate, { loading }] = useAddCulturalEventImageMutation()
  const client = useApolloClient()

	const onClick = () => {
		if (ref.current) {
			ref.current.click()
		}
	}

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const files = event.target.files || []
    
    try {
      for (const file of files) {
        const { data } = await getUploadUrl()
        
        if (!data?.getUploadUrl) 
          return console.log('no data')
        
        const { url, id } = data?.getUploadUrl
        const contentType = file.type[0]

        if (url) {
          await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'image/png'
            }
          })
          await mutate({
            variables: {
              id,
              culturalEventId: props.id,
              ziferblatId: props.ziferblatId,
              contentType
            }})
          await client.reFetchObservableQueries()
        }
      }
    }
    catch (error) { 
      console.log(error) 
    }
  }

	return $(Button, {
    view: 'outlined',
    className: 'add-images-button',
    onClick,
    },
		loading 
      ? $(Spin) 
      : $(Plus, {
			  className: 'add-images-button_plus-icon',
		  }),
		$('input', {
			ref,
			className: 'add-images-button_input',
			type: 'file',
			multiple: true,
			onChange: handleUpload,
		})
	)
}

export default UploadButton