import { createElement as $ } from "react"
import ReactDOM from 'react-dom/client'
import App from './App'

window.onerror = (...args) => {
  console.log('Error')
  console.log(JSON.stringify(args))
  return false
}

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  
  root.render(
    $(App))
  }
