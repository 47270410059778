import { Avatar, AvatarProps, Button } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { MeQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import './style.scss'

const ZiferList: FC<ZiferListProps> = ({
	roles,
	updatedRole,
	handleZiferClick
}) => 
  roles?.map(({ id, role, ziferblat }) =>
		$(Button, {
      className: 'iteratee_button-wrapper',
      key: id,
      selected: id === updatedRole,
      size: 'l',
      view: 'flat',
      width: 'max',
      onClick: () => handleZiferClick(id),
		},  
      $('div', { className: 'iteratee_button' },
        $<AvatarProps>(Avatar, { imgUrl: ziferblat?.avatarUrl || '/favicon.svg' }),
        $(FormattedMessage, { id: `role.${role.name}` as MessageIds}),
        ' ', ziferblat?.i18n[0]?.name)))

type ZiferListProps = {
  roles: MeRoles
  updatedRole: string
  handleZiferClick: (arg0: string) => void
}

export type MeRoles = NonNullable<NonNullable<MeQuery['me']>['user']>['roles']

export default ZiferList