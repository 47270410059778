import { Plus } from '@gravity-ui/icons'
import { Button, Flex } from '@gravity-ui/uikit'
import { CulturalEventFragment } from 'queries'
import { createElement as $, FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import TicketsForm from './TicketsForm'

const AddTicket: FC<CulturalEventFragment> = ({ id }) => {
  const [isAdding, setAdding] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => { 
    if (ref && ref.current) {
      const offset = ref.current.getBoundingClientRect().left
      const y = ref.current.getBoundingClientRect().top + window.scrollY - offset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [isAdding])
  
  if (isAdding) return $('div', { ref }, 
    $(TicketsForm, { 
      setAdding, 
      culturalEventId: id 
    }))

    return $(Button, { onClick: () => setAdding(true) },
      $(Flex, { alignItems: 'center', gap: 2 },
        $(Plus),
        $(FormattedMessage, { id: 'culturalEvent.addTickets' })))
}

export default AddTicket