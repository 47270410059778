import { dateTime } from '@gravity-ui/date-utils'
import Plus from '@gravity-ui/icons/Plus'
import CallToAction from 'components/MobileMenu/CallToAction'
import ZiferblatContext from 'components/ZiferblatContext'
import { useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

const AddCulturalEvent: FC = () => {
  const navigate = useNavigate()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useUpsertCulturalEventMutation({
    variables: {
      input: {
        ziferblatId: ziferblatId,
        startsAt: dateTime().add(1, 'day').toDate(),
        endsAt: dateTime().add(1, 'day').add(1, 'hour').toDate()
      }
    },
    updateQueries: {
      CulturalEvents: (prev, next) => ({
        culturalEvents: [next.mutationResult.data?.upsertCulturalEvent, ...prev.culturalEvents]
      })
    }
  })

  return $(CallToAction, {
    data: Plus,
    loading,
    onClick: () => mutate()
      .then(({ data }) => {
        data?.upsertCulturalEvent &&
          navigate(`/events/${data.upsertCulturalEvent.id}`)})
      })
}

export default AddCulturalEvent