import { Card, Flex, Label, LabelProps, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { TariffFragment } from 'queries'
import { createElement as $, FC, Fragment, PropsWithChildren } from 'react'
import { FormattedDate, FormattedNumber, FormattedTime, useIntl } from 'react-intl'
import './style.scss'

const TariffIteratee = (props: TariffFragment) =>
  $(TariffListItem, { key: props.id, ...props })

const TariffListItem: FC<TariffFragment> = ({
  id,
  hourPrice,
  stopCheck,
  ...props
}) => {
  const intl = useIntl()
  return $(Card, {
    key: id,
    view: 'filled',
    children: $(Fragment, null,
      $(ActiveLabel, props),
      $('div', { className: spacing({ px: 4, pt: 4 }) },
        $(FormRow, { label: intl.formatMessage({ id: 'settings.tariff.hourPrice' }) },
          hourPrice.map(PriceIteratee)),
        $(FormRow, { label: intl.formatMessage({ id: 'settings.tariff.stopCheck' }) },
          $(FormattedNumber, { value: stopCheck, style: 'currency', currency: 'RUB' })),
        $(FormRow, { label: intl.formatMessage({ id: 'settings.tariff.period' }) },
          $(ActiveFrom, props))),
      )
    })
}

const ActiveLabel: FC<ActiveProps> = ({
  activeFrom,
  activeTo
}) => {
  const now = new Date()
  const intl = useIntl()
  let theme: LabelProps['theme'] = 'success'
  let id: MessageIds = 'settings.tariff.active'

  if (activeTo && now >= activeTo) {
    theme = 'normal'
    id = 'settings.tariff.archive'
  } else if (now < activeFrom) {
    theme = 'warning'
    id = 'settings.tariff.upcoming'
  }

  return $(Label, {
    theme,
    size: 'm',
    className: 'tariff_status'
    },
    intl.formatMessage({ id }))
}

const ActiveFrom: FC<ActiveProps> = ({
  activeFrom,
  activeTo
}) =>
  $('div', null,
    $('div', null,
      $(FormattedDate, { value: activeFrom }),
      ' ',
      $(FormattedTime, { value: activeFrom })),
    activeTo &&
      $('div', null,
        $(FormattedDate, { value: activeTo }), ' ',
        $(FormattedTime, { value: activeTo })))

const PriceIteratee = (value: number, index: number) =>
  $(Flex, { key: index, gap: 2 },
    $('div', { style: { width: '3ex' }},
      $(FormattedNumber, { value: index + 1, style:'unit', unit: 'hour' })),
    '—',
    $('div', null,
      $(FormattedNumber, { value, style: 'currency', currency: 'RUB' })))

const FormRow: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children
}) =>
  $(Flex, { className: spacing({ mb: 4 }) },
    $('div', { style: { width: 180 }}, label),
    $('div', null, children))

type ActiveProps = Pick<TariffFragment, 'activeFrom' | 'activeTo' >

export default TariffIteratee