import { dateTime } from '@gravity-ui/date-utils/build/dateTime'
import { FormikProvider, useFormik } from 'formik'
import { CulturalEventCompensationConstraint, CulturalEventCompensationUpdateColumn, CulturalEventFragment, CulturalEventStatusEnum as CulturalEventStatus, CulturalEventsQuery, useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { Form } from './CulturalEventForm'
import { cloneDeep } from '@apollo/client/utilities'

const CulturalEventWithData: FC<PropsWithChildren<CulturalEventFragment>> = (props) => {
  const [mutate] = useUpsertCulturalEventMutation()
  const formik = useFormik<Form>({
    initialValues: {
      title: props.title || '',
      internalComment: props.internalComment || '',
      publicDescription: props.publicDescription || '',
      status: props.status || CulturalEventStatus.Draft,
      startsAt: dateTime({input: props.startsAt}),
      endsAt: dateTime({input: props.endsAt}),
      slug: props.slug || '',
      ziferblatId: props.ziferblatId,
      ...props.compensation && {
        compensation: {
          amount: props.compensation.amount,
          unit: props.compensation.unit,
          currency: props.compensation.currency
        }
      }
    },
    onSubmit: ({ compensation, ...values }) => {
      mutate({
        updateQueries: {
          CulturalEvents: (prev, { mutationResult  }) => {
            const data = cloneDeep(prev) as CulturalEventsQuery
            const updatedEvent = mutationResult.data?.upsertCulturalEvent

            if (!updatedEvent) return prev
            if (values.status === props.status) return prev
            if (values.status === CulturalEventStatus.Draft) {
              if (data.aggregateCulturalEvents.aggregate)
                data.aggregateCulturalEvents.aggregate.count -= 1
              data.culturalEvents = data.culturalEvents.filter(({ id }) => updatedEvent.id !== id)
              data.draftCulturalEvents.push(updatedEvent)
            } else {
              if (data.aggregateCulturalEvents.aggregate)
                data.aggregateCulturalEvents.aggregate.count += 1
              data.draftCulturalEvents = data.draftCulturalEvents.filter(({ id }) => updatedEvent.id !== id)
              data.culturalEvents.push(updatedEvent)
            }
            return data
          }
        },        
        variables: {
          ...!compensation && props.compensation && {
            compensationIds: [props.compensation.id]
          },
          input: {
            id: props.id,
            ...values,
            slug: values.slug?.length ? values.slug : null,
            endsAt: values.endsAt?.toDate(),
            startsAt: values.startsAt?.toDate(),
            status: values.status,
            ...compensation && {
              compensation: {
                onConflict: {
                  constraint: CulturalEventCompensationConstraint.CompensationPkey,
                  updateColumns: [
                    CulturalEventCompensationUpdateColumn.Amount,
                    CulturalEventCompensationUpdateColumn.Currency,
                    CulturalEventCompensationUpdateColumn.Unit,
                  ]
                },
                data: {
                  id: props.compensation?.id,
                  ...compensation
                }
              }
            }
          }
        }
      })
    },
    validateOnBlur: true,
    enableReinitialize: true,
    validate: ({ slug }) => !slug || slug.length <= 42 ? undefined : { slug: 'too long' }
  })

  return $(FormikProvider, { value: formik }, props.children)
}

export default CulturalEventWithData