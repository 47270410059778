import { DateField, DateFieldState } from '@gravity-ui/date-components'
import { ValueBase } from '@gravity-ui/date-components/dist/esm/components/types'
import { DateTime, dateTime } from '@gravity-ui/date-utils'
import { Clock } from '@gravity-ui/icons'
import QrCode from '@gravity-ui/icons/QrCode'
import { Button, Flex, Icon, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import ZiferblatContext from 'components/ZiferblatContext'
import { CheckinsDocument, CheckinsQuery, CulturalEventStatusEnum, CulturalEventsQuery, useCheckInMutation } from 'queries'
import { createElement as $, FC, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import NameInput from './NameInput'
import QRCodeScanner from './QrCodeReader'
import './style.scss'
import { Validation, validate } from './validation'

const AddCheckin: FC<CheckinsQuery> = ({
  checkins, 
  upcomingCulturalEvents
}) => {
  const [name, setName] = useState('')
  const [checkedInAt, setCheckedInAt] = useState<DateTime | null>(dateTime())
  const validation = validate(checkins || [], { id: '', name })
  const props = { name, validation }
  const navigate = useNavigate()
  const [qrAdd, setQrAdd] = useState(false)
  const intl = useIntl()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useCheckInMutation()
  const disabled = name.length < 1 || validation === Validation.Exists
  const showQr = upcomingCulturalEvents?.find(({ tickets }) => tickets.length)

  const culturalEventIteratee = (props: CulturalEventsQuery['culturalEvents'][number]) =>
    $(CulturalEventItem, {
      key: props.id,
      onClick: () => onClick([props.id]),
      loading,
      disabled,
      text: props.title! || intl.formatMessage({ id: 'culturalEvent.noName' }),
      startsAt: props.startsAt,
      endsAt: props.endsAt,
      status: props.status
      })
  
  const onClick = (culturalEventIds: string[]) => mutate({
    variables: {
      ziferblatId,
      name,
      checkedInAt: checkedInAt?.toDate(),
      attendance: culturalEventIds.map((culturalEventId) => ({
        culturalEventId
      }))
    },
    update: (cache, result) => {
      const nextCheckins = [...checkins, result.data?.addCheckin]
        .sort((left, right) => left!.checkedInAt > right!.checkedInAt ? 1 : -1)
      cache.modify({
        fields: {
          checkins: () => nextCheckins
        }
      })
    },
    refetchQueries: [{
      query: CheckinsDocument,
      variables: { ziferblatId }
    }]
  }).then(() => navigate('/checkins'))

  if (qrAdd)
    return $(QRCodeScanner)

  const titleId = `checkins.add${ upcomingCulturalEvents?.length > 0 ? 'Eventless' : '' }`
  const addButton = $(Button, {
    size: 'xl',
    view: 'action',
    onClick: () => onClick([]),
    loading,
    pin: showQr ? 'round-brick' : 'round-round',
    disabled,
    style: {
      flexGrow: 1
    }
    },
    intl.formatMessage({ id: titleId as MessageIds }))

  return $(Flex, {
    direction: 'column',
    gap: 2
    },
    $(NameInput, { setName, ...props }),
    $(DateField, {
      format: 'HH:mm',
      size: 'xl',
      value: checkedInAt as DateFieldState['value'],
      onUpdate: setCheckedInAt as ValueBase<any>['onUpdate']
    }),
    [...upcomingCulturalEvents || []]
      .sort((left, right) => left.startsAt! > right.startsAt! ? 1 : -1)
      .map(culturalEventIteratee),
    !showQr
      ? addButton
      : $(Flex, null,
          addButton,
          $(Button, {
            size: 'xl',
            view: 'outlined',
            pin: 'brick-round',
            onClick: () => setQrAdd(true)
            },
            $(Icon, { data: QrCode }))))
}

const CulturalEventItem: FC<CulturalEventItemProps> = ({
  onClick,
  loading,
  text,
  disabled,
  startsAt
}) => 
  $(Button, {
    size: 'xl',
    view: 'flat',
    className: 'add-button', 
    onClick,
    loading,
    disabled
    }, 
    $('div', null, text), 
    $(Flex, { alignItems: 'center', gap: 1, className: spacing({ ml: 2 })}, 
      $(Clock),
      startsAt?.toLocaleTimeString(['ru'], { timeStyle: 'short' })))

type CulturalEventItemProps = {
  onClick: () => void
  loading: boolean
  disabled: boolean
  text: string
  startsAt: Date,
  endsAt: Date,
  status: CulturalEventStatusEnum
}

export default AddCheckin