import { Check, Clock } from "@gravity-ui/icons"
import { Flex, Icon, Text, spacing } from "@gravity-ui/uikit"
import { createElement as $, FC } from "react"
import { FormattedDate } from "react-intl"
import { TransactionProps } from "./TicketsList"

const Transactions: FC<{ transaction: TransactionProps[] }> = ({ transaction }) => 
  $(Flex, { 
    direction: 'column', 
    gap: 2, 
    className: spacing({ pt: 2 }), 
    style: { borderTop: '1px solid var(--g-color-line-generic)' }
    }, 
    transaction.map(TransactionItemIteratee))
    
const TransactionItemIteratee = (props: TransactionProps) =>
  $(TransactionItem, { key: props.id, ...props })

const TransactionItem: FC<TransactionProps> = ({ body, createdAt, sum, attendance }) => {
  const { client_email }: TransactionBody = body
  const icon = attendance ? Check : Clock

  return $(Flex, { justifyContent: 'space-between', alignItems: 'center' }, 
    $(Flex, { gap: 4, alignItems: 'center' },
      $(Icon, { data: icon }),
      $(Text, { whiteSpace: 'nowrap' },
        $(FormattedDate, { 
          value: createdAt, 
          day: '2-digit', 
          month: 'long', 
          year: undefined
        }))), 
    client_email && $(Text, { 
      variant: 'subheader-1', 
      ellipsis: true,
      className: spacing({ pl: 4 })
      }, 
      client_email)
    )
}

type TransactionBody = {
  client_email: string
}

export default Transactions