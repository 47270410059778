import { useApolloClient } from '@apollo/client'
import { TrashBin, Xmark } from '@gravity-ui/icons'
import { Button, Flex, Icon, Modal, spacing } from '@gravity-ui/uikit'
import { useRemoveCulturalEventImageMutation } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import './style.scss'

const RemoveImageButtonWithConfirmation: FC<{ id: string }> = ({ id }) => {
  const client = useApolloClient()
  const [mutate, { loading }] = useRemoveCulturalEventImageMutation({ variables: { id }})
  const [open, setOpen] = useState(false)

  const handleImageRemove = () => {
    mutate()
      .then((res) => {
        console.log(res)
        client.refetchQueries({ // FIXME update cache instead
          include: ["CulturalEventImages"],
        })
      })
  }

  return $(Fragment, null, 
    $(Button, {
      view: 'flat',
      size: 'xs',
      onClick: () => setOpen(true)
      }, 
      $(Icon, { data: Xmark })),
    $(Modal, { 
      open, 
      onClose: () => setOpen(false),
      },
      $(Flex, { 
        direction: 'column', 
        gap: 4, 
        alignItems: 'center', 
        className: spacing({ p: 4 }) 
        },
        $(FormattedMessage, { id: 'culturalEvent.images.confirmRemove' }), 
        $(Flex, { gap: 2 },
          $(Button, { 
            view: 'action',
            onClick: () => handleImageRemove(),
            loading
            },
            $(Icon, { data: TrashBin }),
            $(FormattedMessage, { id: 'culturalEvent.remove' })), 
          $(Button, { 
            view: 'outlined',
            onClick: () => setOpen(false),
            disabled: loading
            }, 
            $(Icon, { data: Xmark }),
            $(FormattedMessage, { id: 'culturalEvent.cancel' })))
    )))
}

export default RemoveImageButtonWithConfirmation