import { createElement as $, FC } from 'react'
import { Sheet } from '@gravity-ui/uikit'
import { CheckinsQuery } from 'queries'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import AddCheckin from '../Forms/AddCheckin'
import ViewCheckin from '../Forms/ViewCheckin'

const Edit: FC<CheckinsQuery> = (data) => {
  const match = useMatch('/checkins/:page')
  const navigate = useNavigate()
  return $(Sheet, {
    className: 'checkins_mobile_sheet',
    visible: !!match?.params.page,
    onClose: () => navigate('/checkins')
    },
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(ViewCheckin, data)
        }),
      $(Route, {
        path: addPath,
        element: $(AddCheckin, data)
        })))
}

export const addPath = 'new'

export default Edit