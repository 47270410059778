import PauseIcon from '@gravity-ui/icons/Pause'
import PlayIcon from '@gravity-ui/icons/Play'
import { Button, Icon } from '@gravity-ui/uikit'
import { addSeconds } from 'date-fns/fp/addSeconds'
import { CheckinFragment, useCheckinPauseMutation, useCheckinResumeMutation } from 'queries'
import { createElement as $, FC } from 'react'

export const Pause: FC<{ checkinId: CheckinFragment['id'] }> = ({ checkinId }) => {
  const [mutate, { loading }] = useCheckinPauseMutation({ variables: { checkinId }})
  return $(Button, {
    size,
    loading,
    view: 'action',
    pin: 'brick-round',
    onClick: () => mutate()
    },
    $(Icon, { data: PauseIcon }))
}

export const Resume: FC<PauseType> = ({ id, startedAt }) => {
  const [mutate, { loading }] = useCheckinResumeMutation({
    variables: {
      id,
      // This is to guarantee that range is more than 1 second
      // so it works properly on the server
      endedAt: addSeconds(1, new Date())
  }})
  return $(Button, {
    size,
    loading,
    width: 'max',
    view: 'outlined-action',
    onClick: () => mutate(),
    },
    $(Icon, { data: PlayIcon }))
}

const size = 'xl'

type PauseType = CheckinFragment['pause'][number]