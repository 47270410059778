import { CheckinFragment } from 'queries'
import { IntlShape } from 'react-intl'

const groupCheckinsByCulturalEvents = <T extends CheckinFragment>(
  intl: IntlShape,
  search: string,
  checkins: T[]
): Group<T>[] => {
  const eventless = intl.formatMessage({ id: 'checkins.eventless' })
  const map = new Map<string, T[]>([[eventless, []]])
  const regex = search ? new RegExp(search, 'i') : null // Adjusted the regex flags

  for (const checkin of checkins) {
    if (regex && !checkin.name?.match(regex)) {
      continue
    } else if (checkin.attendance.length === 0) {
      pushToMap(map, eventless, checkin)
    } else {
      for (const { culturalEvent } of checkin.attendance) {
        const title = culturalEvent.title ?? eventless // Handle undefined titles
        pushToMap(map, title, checkin)
      }
    }
  }

  if (map.get(eventless)?.length === 0) map.delete(eventless)

  const result: Group<T>[] = []

  for (const [name, groupedCheckins] of map.entries()) {
    groupedCheckins.sort((a, b) => (new Date(b.checkedInAt) > new Date(a.checkedInAt) ? -1 : 1)) // Corrected the sort function
    result.push({ name, checkins: groupedCheckins })
  }

  return result
}

const pushToMap = <T extends CheckinFragment>(
  map: Map<string, T[]>,
  key: string,
  value: T
) => {
  const current = map.get(key)
  if (!current) {
    map.set(key, [value])
  } else {
    current.push(value)
  }
}

export type Group<T> = {
  name: string
  checkins: T[]
}

export default groupCheckinsByCulturalEvents
