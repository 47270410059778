import { Text } from '@gravity-ui/uikit'
import { createElement as $ } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { MessageIds } from './IntlProvider'
import './style.scss'

const Empty = () => {
  const { pathname } = useLocation()
  const location = pathname.split('/')[1] as keyof WithoutMain

  return $(Text, { as: 'div', variant: 'body-2', className: 'checkins' },
    $(FormattedMessage, { id: `empty.${location}` }))
}

type Pages = Extract<MessageIds, `main.${string}`>

type WithoutMain = {
  [K in Pages as K extends `main.${infer Rest}` ? Rest : never]: string;
};

export default Empty