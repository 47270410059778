import { Dots9 } from '@gravity-ui/icons'
import { Sheet } from '@gravity-ui/uikit'
import UserContent from 'components/User'
import useNavItems from 'hooks/useNavItems'
import { MeQuery } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import AdornmentButton from './AdornmentButton'
import MobileNavigation from './MobileNavigation'
import './style.scss'

const Menu: FC<MeQuery> = (data) => {
  const [isOpen, setOpen] = useState(false)
  const navItems = useNavItems()

  return $(Fragment, null,
    $(AdornmentButton, { data: Dots9, onClick: () => setOpen(true) }),
    $(Sheet, {
      visible: isOpen,
      onClose: () => setOpen(false),
      },
      $(MobileNavigation, { navItems }),
      $(UserContent, data)))
}

export default Menu