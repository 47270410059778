import { Flex } from "@gravity-ui/uikit";
import { createElement as $, FC, PropsWithChildren } from "react";

const MessageContainer: FC<PropsWithChildren> = ({ children }) =>
  $(Flex, { 
    width: '100%',
    height: '100svh',
    justifyContent: 'center',
    alignItems: 'center',
    }, 
    children)

export default MessageContainer