import { Flex } from '@gravity-ui/uikit'
import { createElement as $, Fragment } from 'react'
import AddTariff from '../AddTariff'
import { TariffsProps } from '../Mobile/Content'
import TariffIteratee from '../TariffIteratee'
import './style.scss'
import Empty from 'components/Empty'

const Desktop = (props: TariffsProps) => {
    
  if (!props.data) return (
    $(Fragment, null, 
      $(Empty), 
      $(AddTariff))
  )
    
  return $('div', { className: 'desktop-tariff_container'},
    $(Flex, { gap: 2, direction: 'column' },
      props.data?.tariffs.map(TariffIteratee)),
    $(AddTariff, props))
}

export default Desktop