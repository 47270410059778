import { Progress } from '@gravity-ui/uikit'
import { useInView } from 'framer-motion'
import { FC, ReactElement, createElement as $, useRef, useEffect } from 'react'

const Dniwe: FC<DniweProps> = ({
  onReached,
  content = $(Progress)
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref)
  useEffect(() => {
    if (isInView) onReached()
  }, [isInView, onReached])
  return $('div', { ref }, content)
}

type DniweProps = {
  onReached: () => void
  content?: ReactElement
}

export default Dniwe