import { ArrowLeft, Check, FloppyDisk } from '@gravity-ui/icons'
import { Button, Card, Icon, Text } from '@gravity-ui/uikit'
import { useFormikContext } from 'formik'
import { createElement as $, FC, Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { CulturalEventProps } from '..'
import Attendance from '../Attendance'
import CulturalEventForm, { Form } from '../CulturalEventForm'
import Images from '../Images'
import './style.scss'

const CulturalEventMobile: FC<CulturalEventProps> = ({ culturalEvent, loading }) =>
  $(Fragment, null,
    $(Fragment, null,
      $(Text, null, 
        $(FormattedMessage, { id: 'culturalEvent.images' })),
      $(Images, culturalEvent)),
    $(CulturalEventForm),
    $(Attendance),
    $(Controls, { loading }))

const Controls = ({ loading }: Pick<CulturalEventProps, 'loading'>) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const formik = useFormikContext<Form>()
  const [submitted, setSubmitted] = useState(false)
  const submitting = formik.isSubmitting

  const onClick = () => {
    if (!submitted) {
      formik.submitForm()
      .then(() => setSubmitted(true))
      // .then(() => navigate('/events'))
    }
  }

  useEffect(() => {
    setTimeout(() => { 
      setSubmitted(false) 
    }, 2000)
  }, [submitting])


  return $(Card, {
    className: 'cultural-event-container_controls',
    view: 'filled',
    children: ''
    },
    $(Button, {
      className: 'cultural-event-container_controls_button',
      size: 'l',
      view: 'flat',
      onClick: () => navigate('/events')
      },
      $(Icon, { data: ArrowLeft }),
      $(Text, null, intl.formatMessage({ id: 'main.events' }))),
    $(Button, {
      size: 'l',
      view: `outlined-${!submitted ? `action` : `success`}`,
      onClick,
      loading: loading || submitting,
      disabled: (!submitted && !formik.dirty) || !formik.isValid
      },
      $(Icon, { data: !submitted ? FloppyDisk : Check })))
}

export default CulturalEventMobile