import { ArrowRightFromSquare } from "@gravity-ui/icons"
import { Avatar, AvatarProps, Button, Card, Flex, Icon, Text, spacing } from "@gravity-ui/uikit"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { AliasIteratee, ProfileProps } from "."
import './style.scss'

const Desktop: FC<ProfileProps> = ({ data, aliases, loading, name, surname, onClick }) => {
  return $('div', { className: 'profile-info_desktop' },
    $(Flex, { grow: 1, direction: 'column', justifyContent: 'center' }, 
      $(Card, { className: spacing({ p:4 }), children: '' },
        $(Flex, { direction: 'column', alignItems: 'flex-start', gap: 4, },
          $(Flex, { gap: 2, alignItems: 'center' }, 
            $<AvatarProps>(Avatar, { 
              size: 'xl',
              imgUrl: data?.me?.user?.avatarUrl || '/favicon.svg' 
            }),
            $(Text, { style: { fontSize: 24 }}, `${name} ${surname}`)),
          $(Flex, { direction: 'column', alignItems: 'flex-start' }, aliases.map(AliasIteratee))),
          $(Flex, { direction: 'row', alignItems: 'flex-end', width: '100%', justifyContent: 'flex-end' }))),
    $(Flex, { gap: 4, justifyContent: 'flex-end', className: spacing({ mt: 4 }) },
      $(Button, { onClick, disabled: loading, style: { width: '100%' }},
      $(FormattedMessage, { id: `logout${loading ? '.loading' : ''}` }),
      $(Icon, { data: ArrowRightFromSquare }))))
}

export default Desktop