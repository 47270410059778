import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { DateTime, dateTime, dateTimeParse } from '@gravity-ui/date-utils'
import { Flex, Link, Text, TextArea, useLayoutContext } from '@gravity-ui/uikit'
import FormattedDuration from 'components/FormattedDuration'
import { useFormikContext } from 'formik'
import { CompensationCurrencyEnum, CompensationUnitEnum, CulturalEventStatusEnum } from 'queries'
import { createElement as $, FC, RefObject, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import StatusSelect from '../StatusSelect'
import Compensation from './Compensation'
import './style.scss'

const CulturalEventForm: FC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors
  } = useFormikContext<Form>()
  const intl = useIntl()
	const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const onBlur = (name: string) => () => handleBlur({ target: { name }})
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const direction = isMobile ? 'column' : 'row'

  const startTimeInputRef = useRef<HTMLInputElement>(null)
  const endTimeInputRef = useRef<HTMLInputElement>(null)

  const handleInputFocus = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current && isMobile) 
      try { ref.current.showPicker() }
      catch (error) { console.log(error) }
  }

  return $(Flex, {
      gap: isMobile ? 0 : 4,
      direction: 'column',
    },
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.title' }),
      },
      $(TextArea, {
        value: values.title || '',
        onUpdate: onChange('title'),
        onBlur: onBlur('title'),
        error: touched.title && errors.title,
        placeholder: intl.formatMessage({ id: 'culturalEvent.form.title' })
      })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.dateStart' })
      }, 
      $(DatePicker, {
        value: values.startsAt,
        onUpdate: (date) => {
          onChange('startsAt')(date)
          onChange('endsAt')(date?.add(1, 'hour'))
        },
        format: 'DD.MM.YYYY',
        onBlur: onBlur('startsAt'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.form.dateStart' })
      })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.time' }),
      },
      $(Flex, { style: { position: 'relative' }},
        $('div', { style: { position: 'relative', width: '50%'}},
          $(DatePicker, {
            format: 'HH:mm',
            pin: 'round-clear',
            value: values.startsAt,
            onFocus: () => handleInputFocus(startTimeInputRef),
            onUpdate(value) {
              onChange('startsAt')(value)
              onChange('endsAt')(value?.add(1, 'hour'))
            },
            placeholder: intl.formatMessage({ id: 'culturalEvent.form.timeStart' })
            }),
          $('input', { 
            ref: startTimeInputRef, 
            type: 'time', 
            value: values.startsAt?.format('HH:mm'),
            onChange(event) {
              const newStartTime = dateTimeParse(values.startsAt)?.format(`YYYY-MM-DDT${event.target.value}Z`)
              onChange('startsAt')(dateTime({ input: newStartTime }))
              onChange('endsAt')(dateTime({ input: newStartTime }).add(1, 'hour'))
            },
            style: { 
              position: 'absolute',
              pointerEvents: 'none',
              left: 0,
              bottom: 0,
              opacity: 0, 
            }})),
        $('div', { style: { position: 'relative', width: '50%'}},
          $(DatePicker, {
            format: 'HH:mm',
            pin: 'clear-round',
            value: values.endsAt,
            onFocus: () => handleInputFocus(endTimeInputRef),
            onUpdate(value) {
              onChange('endsAt')(value)
              // FIXME check edge cases
            },
            placeholder: intl.formatMessage({ id: 'culturalEvent.form.timeEnd' }),
            }),
          ),
          $('input', { 
            ref: endTimeInputRef, 
            type: 'time', 
            value: values.endsAt?.format('HH:mm'),
            onChange(event) {
              const newEndTime = dateTimeParse(values.endsAt)?.format(`YYYY-MM-DDT${event.target.value}Z`)
              onChange('endsAt')(dateTime({ input: newEndTime }))
            },
            style: {
              position: 'absolute',
              pointerEvents: 'none',
              left: '50%',
              bottom: 0,
              opacity: 0, 
            }})),
        $(Text, { className: 'cultural-event-form_duration-holder', color: 'hint' },
          values.endsAt && values.startsAt &&
            $('div', { className: 'cultural-event-form_duration-holder_durationTime' },
              $(FormattedDuration, { value: values.endsAt.diff(values.startsAt) / 1000, unit: 'second' })))),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.description' })
      },
      $(TextArea, {
        value: values.publicDescription || '',
        onUpdate: onChange('publicDescription'),
        note: $(FormattedMessage, {
          id: 'culturalEvent.form.description.note', 
          values: {
            link: $(Link, { 
              href: 'https://www.markdownguide.org/cheat-sheet', 
              target: '_blank' 
            }, intl.formatMessage({ id: 'culturalEvent.form.description.note.markdown' }))
          }}),
        minRows: 2
        })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.internalComment' }) 
      },
      $(TextArea, {
        value: values.internalComment || '',
        onUpdate: onChange('internalComment'),
        minRows: 2
        })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.compensation' }), 
      },
      $(Compensation)),
    isMobile && 
      $(FormRow, {
        direction,
        label: intl.formatMessage({ id: 'culturalEvent.status' })
        },
        $(StatusSelect, { isMobile })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.slug' }),
      },
      $(TextArea, {
        value: values.slug || '',
        onUpdate: (value) => onChange('slug')(sanitizeSlug(value)),
        placeholder: intl.formatMessage({ id: 'culturalEvent.slug.placeholder' }),
        validationState: !!errors.slug ? 'invalid' : undefined,
        errorMessage: intl.formatMessage({ id: 'culturalEvent.slug.error' })
      })))
  }

const DISALLOWED_CHARACTERS = /[^a-z0-9-+]+/g;

const sanitizeSlug = (value: string) => 
  value.trimStart()
    .replace(' ', '-')
    .replace(/-+/g, '-')
    .replace(DISALLOWED_CHARACTERS, '')

export type Form = {
  startsAt: DateTime | null
  endsAt: DateTime | null
  title: string
  internalComment: string
  publicDescription: string
  status: CulturalEventStatusEnum
  ziferblatId: string
  slug: string | null
  compensation?: {
    unit: CompensationUnitEnum
    currency: CompensationCurrencyEnum
    amount: number
  }
}

export default CulturalEventForm