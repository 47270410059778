import { Plus } from '@gravity-ui/icons'
import MobileList from 'components/MobileList'
import CallToAction from 'components/MobileMenu/CallToAction'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { createElement as $, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Content, { StaffProps } from '../Content'
import Edit from './Edit'

const Mobile: FC<StaffProps> = (props) =>
  $(MobileMenuContainer, {
    fab: $(AddButton),
    label: 'header.button.search.staff'
    },
    !props.loading && props.data && 
      $(Edit, props.data),
    $(MobileList, null, 
      $(Content, props)))

const AddButton = () => {
  const navigate = useNavigate()
  return $(CallToAction, {
    data: Plus,
    onClick: () => navigate(`/staff/add`)
  })
}

export default Mobile