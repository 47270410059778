import { Link as LinkIcon, Ticket } from '@gravity-ui/icons'
import { Card, Flex, Label, LabelProps, Link, Text, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { CulturalEventFragment, PaymentTransaction } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl'
import Transactions from './Transactions'
import './style.scss'
import RemoveTicketButtonWithConfirmation from './RemoveButton'

const TicketItemIteratee = (props: TicketItemProps) => 
  $(TicketItem, { key: props.id, ...props})

const TicketItem: FC<TicketItemProps> = ({ id, activeFrom, price, maxCount, transaction, customUrl }) => {
  const count = transaction.length
  const sum = transaction.reduce((acc, transaction) => acc + transaction.sum, 0)
  const ticketsSold = maxCount ? count + '/' + maxCount : count
  const messageId: MessageIds = `culturalEvent.tickets.${ maxCount ? 'soldWithMaxCount' : 'soldCount' }`
  const maxCountWithNoTransactions = maxCount && !count

  const children = $('div', null, 
    $(TopBar, { activeFrom, count, sum, id }),
    $(Flex, { direction: 'column', gap: 2, className: spacing({ p: 4 }) }, 
      $(Flex, { 
        direction: 'column', 
        gap: 2,
        },
        customUrl && 
          $(Link, { 
            href: customUrl, 
            target: '_blank',
            view: 'secondary',
            style: { width: 'fit-content' }
            },
            $(Flex, { gap: 2 },
              $(LinkIcon),
              customUrl)),
        $(Flex, { justifyContent: 'space-between' },
          $(FormattedMessage, { id: 'culturalEvent.tickets.price' }),
          $('div', null,
            $(FormattedNumber, { value: price, style: 'currency', currency: 'RUB' }))),    
        maxCountWithNoTransactions && 
          $(Flex, { justifyContent: 'space-between' },
            $(FormattedMessage, { id: 'culturalEvent.tickets.maxCount' }),
            $(Text, null,
              $(FormattedMessage, { id: 'culturalEvent.tickets.count', values: { count: maxCount || '' }})))),
      !!count &&
        $(Fragment, null,
          $(Flex, { justifyContent: 'space-between' },
            $(FormattedMessage, { id: messageId }),
            $(Text, null, 
              $(FormattedMessage, { id: 'culturalEvent.tickets.count', values: { count: ticketsSold || '' } }))),
          $(Flex, {
            direction: 'column',
            gap: 2,
            },
            $(Flex, { justifyContent: 'space-between' },
              $(FormattedMessage, { id: 'culturalEvent.stats.sum' }),
              $('div', null,
                $(FormattedNumber, { value: sum, style: 'currency', currency: 'RUB' })))),
          $(Transactions, { transaction }))))

  return $(Card, { view: 'filled', children })
}

const TopBar: FC<{ activeFrom: Date, count: number, sum: number, id: string }> = ({ activeFrom, count, sum, id }) => {
  const status = new Date() < new Date(activeFrom) ? 'planned' : 'active'
  const messageId: MessageIds = `culturalEvent.tickets.${status}`
  const theme: LabelProps['theme'] = status === 'planned' ? 'warning' : 'normal'

  return $(Label, { className: 'tickets-label', theme },
  $(Flex, { justifyContent: 'space-between', alignItems: 'center' },
    $(Flex, { gap: 2, alignItems: 'center' }, 
      $(Ticket),
      $(Text, null,
        $(FormattedMessage, { id: messageId }),
        ' ',
        $(FormattedDate, { 
          value: activeFrom, 
          day: '2-digit', 
          month: 'long', 
          year: undefined 
        }),
        ' ',
        $(FormattedTime, { value: activeFrom }))),
      $(RemoveTicketButtonWithConfirmation, { id })
    ))
}

type TicketItemProps = CulturalEventFragment['tickets'][0]
export type TransactionProps = Pick<PaymentTransaction, 'id' | 'body' | 'createdAt' | 'sum' | 'attendance'>

export default TicketItemIteratee